import React from "react"
import { Container } from "react-bootstrap"
import { Title } from "../ui"
import OurPortfolioCard from "../Global/OurPortfolioCard/OurPortfolioCard"
import { allOurPortfolio } from "../../constants"
import "./index.scss"

const OurPortfolio = () => {
  return (
    <div className="ourPortfolio__container">
      <Container>
        <Title header="Fin Ventures" color="BlackRussian" />
        {/* <p>
          Our growing stable of brands includes our core products and complementary investments, known as Fin Ventures. Together with our core products, these brands serve hundreds of thousands of customers across Sub-Saharan Africa. 
        </p> */}
        <p>Fin Ventures are the company's minority participations in three areas:</p>
        <p>B2C: Consumer-facing vertically focused companies supported by Fin's credit & financial services expertise. </p>
        <p>B2B: Business-facing vertically-focused companies informed by Fin's own services needs.</p>
        <p>Credit Institutions: Operators in the same space as Fin in which Fin holds participations with a potential to add them to the group in an expansion to new markets.</p>
        <div className="ourPortfolio__section">
          {allOurPortfolio.map(brands => {
            const {
              facebookUrl,
              linkedInUrl,
              id,
              title,
              text,
              image,
              website,
            } = brands

            return (
              <div key={id}>
                <OurPortfolioCard
                  {...{
                    facebookUrl,
                    linkedInUrl,
                    title,
                    text,
                    image,
                    website,
                  }}
                />
              </div>
            )
          })}
        </div>
        <br />
      </Container>
    </div>
  )
}

export default OurPortfolio
