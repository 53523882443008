import React from "react"
import "./OurPortfolioCard.scss"
import LinkIcon from "../../../assets/images/our-portfolio/link-icon.svg"

const OurPortfolioCard = ({
  facebookUrl,
  linkedInUrl,
  title,
  text,
  image,
  website,
}) => {
  return (
    <div className="ourPortfolioCard__container">
      {/* <object type="image/svg+xml" data={image} className={`ourPortfolioCard__logo ${title}`}></object> */}
      <img
        src={image}
        alt={title}
        className={`ourPortfolioCard__logo ${title}`}
      />
      <div className="ourPortfolioCard__landing">
        <h6>{title}</h6>
        <p>{text}</p>
        <div
          className={
            website
              ? "ourPortfolioCard__bottomLinks"
              : "ourPortfolioCard__bottomLinksWebsite"
          }
        >
          {website && (
            <a href={website} title={title} target="_blank" rel="noreferrer">
              <img
                src={LinkIcon}
                alt="link icon"
                className={"ourPortfolioCard__icon"}
              />
            </a>
          )}
          <div className="ourPortfolioCard__bottomSocialLinks">
            {facebookUrl && (
              <a
                href={facebookUrl}
                title={facebookUrl}
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            )}
            {linkedInUrl && (
              <a
                href={linkedInUrl}
                title={linkedInUrl}
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurPortfolioCard
