import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import OurPortfolio from "../components/FinVentures"

const OurPortfolioPage = () => (
  <Layout>
    <Seo title="Fin Ventures" />
    <OurPortfolio />
  </Layout>
)

export default OurPortfolioPage
